import { observer } from "mobx-react-lite";
import LandingSecondView from "../../components/home/LandingSecondView";
import OurPromise from "../../components/home/OurPromise";
import Navbar from "../../components/shared/navbar/Navbar";
import { SlantedService } from "../../components/service-slanted/Service";
// import { SingleHero } from "../../components/home/SingleHero";
import { Home } from "../../components/home/Home";

export default observer(function HomePage() {
  return (
    <>
      <Navbar />
       <Home />
      <LandingSecondView />
      <SlantedService />
      <OurPromise />
    </>
  );
});
