import url1 from "../assets/images/gate-new.jpeg";
import url2 from "../assets/images/spareparts-new.jpeg";
import url3 from "../assets/images/hub-new.jpeg";
import url4 from "../assets/images/blue-home.jpg";
import url5 from "../assets/images/tyre-new.jpeg";
const people = [
  {
    id: 1,
    url: url4,
    // quote1: "",
    // quote2: "",
  },
  {
    id: 2,
    url: url1,
    quote1: "We are on a mission to build the first ultra-modern one",
    quote2: "stop truck center in regional West Africa",
  },
  {
    id: 3,
    url: url2,
    quote1: "This is a 20 bay state-of-the-art'Truck Repair Workshop'",
    quote2: "equipped to service 20 trucks simultaneous",
  },
  {
    id: 4,
    url: url3,
    quote1: "Steel and Aluminiun Fabrication Hub designed to accomodate",
    quote2: "the construction of 50 truck bodies and trailers",
  },
  {
    id: 5,
    url: url5,
    quote1: "The Tyre Centre has the capacity to re-thread 100 Tyres per week",
    quote2: "",
  },
];

export default people;
