import "./OurProducts.css";
import TruckHubImg from "../../assets/images/truck hub.jpg";
import SparePartsImg from "../../assets/images/spareparts-new.jpeg";
import TyreMgtImg from "../../assets/images/truck centre.jpg";
import TrainingCenterImg from "../../assets/images/training-new.jpeg";
import OurProduct from "./templates/OurProduct";

export default function OurProducts() {
  return (
    <div>
      <br />
      <h2 className="what-we-bring-title">THE JOURNEY</h2>
      <br />
      <p className="what-we-bring-p">
          We are on a mission to build the first of its kind one stop truck
          repair and maintenance ecosystem that guarantees maximum uptime for
          heavy duty automobiles. Our investment in critical assets that will
          drive this mission is a project of innovation in design and technology
          sitting on approximately 82,000 square meters land space located along
          Sagamu-Benin highway, Ogun State. It is designed to accommodate the
          following:
        </p>
      <OurProduct
        name="State-of-The Art Workshop"
        description="A key part of The Truck Arena project is a 20 Bay State-of-the-Art Truck Repair workshop, equipped with genuine spare parts and accessories to service 20 trucks at the same time, supported by 10 Mobile Workshop Van Unit equipped for roadside repairs."
        imgUrl={SparePartsImg}
        position={1}
      />
      <OurProduct
        name="Tyre Management Centre"
        description="The tyre management centre will have the capacity to re-thread 100 tyres per day. This will ensure we meet the tyre needs of our customers."
        imgUrl={TyreMgtImg}
        position={2}
      />
      <OurProduct
        name="Steel and Aluminium Fabrication Hub"
        description="This is another critical part of our investment in the arena. It is designed to accommodate the construction of 50 truck bodies and trailers simultaneously. All heavy duty automobile body works will take place in the Steel and Aluminum Fabrication Hub."
        imgUrl={TruckHubImg}
        position={1}
      />
      <OurProduct
        name="The Training Centre"
        description="This a 20-seater modern training centre equipped for technician and truck driver training. Adjacent to the training centre is truck Driver Training Tracks for practical driving course."
        imgUrl={TrainingCenterImg}
        position={2}
      />
    </div>
  );
}
