import { useEffect } from "react";
import AppFaq from "../../components/faq/FAQ";
import Navbar from "../../components/shared/navbar/Navbar";

export default function Questions() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar />
      <AppFaq />
    </div>
  );
}
