import { useState } from "react";
import { Link } from "react-router-dom";
import { Divider } from "semantic-ui-react";
import "./Footer.css";
import { observer } from "mobx-react-lite";
import { IoMdArrowDropdown } from "react-icons/io";

export default observer(function Footer() {
  const [click, setClick] = useState(false);
  const closeMobileMenu = () => setClick(false);

  return (
    <footer className="foot-container">
      <div className="row footer-row footer-container">
        <div className="col-lg-3 offset-lg-1 contact-us">
          <h3 className="mt-lg-0 mt-3">CONTACT US</h3>
          <span className="company-description">
            Phone: <a href="tel:+2348083779882">+2348083779882</a>
          </span>
          <br />
          <span className="company-description">
            Email:{" "}
            <a href="mailto:info@thetruckarena.com">info@thetruckarena.com</a>
          </span>
          <br />
          <span className="company-description">
            Address: 4th Floor, Niger Insurance House 302/304 Ikorodu Road,
            Anthony - Lagos.
          </span>
        </div>

        <div className="col-lg-2 quick_links_container">
          <h3 className="mt-lg-0 mt-3">QUICKLINKS</h3>
          <ul className="quick-links-container">
            <li>
              <Link to="/about-us" className="quick-links">
                About Us
              </Link>
            </li>

            <li>
              <Link to="#" className="quick-links">
                Services
                <IoMdArrowDropdown />
              </Link>
            </li>
            <li>
              <Link to="/faq" className="quick-links">
                FAQ
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <Divider />

      <div className="footer-row">
        <div className="text-center copy-right-text">
          &copy; {new Date().getFullYear()} The Truck Arena | All Rights
          Reserved
        </div>
      </div>
    </footer>
  );
});
