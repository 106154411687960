import { useState } from "react";
import { Link } from "react-router-dom";
import "./Narbar.css";
import Dropdown from "../service-dropdown/ServiceDropdown";
import Logo from "../../../assets/images/TTA-logo-icon.png";
import { IoMdArrowDropdown } from "react-icons/io";
import { FaTimes, FaBars } from "react-icons/fa";


function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  
  return (
    <>
      <nav className="navbar">
        <Link to={"/"} className="logo-container">
          <img alt="Company-Logo" src={Logo} className="logo-img" />
        </Link>
        ;
        <div className="menu-icon" onClick={handleClick}>
          {click ? <FaTimes /> : <FaBars />}
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link to="/" className="nav-links">
              HOME
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/about-us"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              ABOUT US
            </Link>
          </li>
         
          <li className="nav-item">
            <Link to="#" className="nav-links">
              SERVICES
              <IoMdArrowDropdown />
            </Link>
            <ul className="dropdown">
              <li>
                <Link to="/truckinspection" className="dropdown-nav" onClick={closeMobileMenu}>
                  Truck Inspection & Uptime Service
                </Link>
              </li>
              <li>
                <Link to="/truckrepair" className="dropdown-nav" onClick={closeMobileMenu}>
                  Truck Repair & Maintenance
                </Link>
              </li>
              <li>
                <Link to="/tyremanagement" className="dropdown-nav tyre-nav" onClick={closeMobileMenu}>
                  Tyre Management Service
                </Link>
              </li>
            </ul>
          </li>
          <li className="nav-item">
            <Link to="/faq" className="nav-links" onClick={closeMobileMenu}>
              FAQ's
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Navbar;
